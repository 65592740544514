<template>  
	<span class="px-2 badge text-dark align-text-bottom" style="cursor: pointer" @click="logout">
		<!-- {{ user.username }} -->
		<img class="img" src="../assets/user.png">
	</span>  
</template>

<script>
import Swal from 'sweetalert2';
export default {
	props: {
		user: { required: true }
	},

	methods: { 
		logout: async function() {
			var result = await Swal.fire({
				icon: 'question',
				title: 'Vuoi davvero terminare la sessione?',
				showCancelButton: true,
				confirmButtonText: "Termina",
				cancelButtonText: "Non terminare",
			});

			// Termina la sessione
			if(result.isConfirmed)
			{
				window.dpixel_session.clear(); 
				this.$router.push('/');
			}
		}
	}
}
</script>

<style>
.img {
	height: 24px;
	width: 24px;
	padding: 4px;
}
</style>