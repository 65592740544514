<template>
    <button @click="toggle" style="border: 0px;">
        <small>
            <i v-if="acquired">Schermo sempre attivo</i>
            <i v-if="!acquired">Blocca</i>
        </small>
        <img style="height: 20px; margin: 3px" src="../assets/screenlock2.png" v-if="acquired">
        <img style="height: 20px; margin: 3px" src="../assets/screenlock.png" v-else>
    </button>
</template>

<script>
import NoSleep from 'nosleep.js';

export default {

    data: function() {
        return {
            init: false,
            acquired: false,
            noSleep: new NoSleep()
        }
    },

    mounted: function () {
    },
    

    methods: {
        acquire: function() {
            
            if(!this.acquired)
            {
                this.acquired = true;
                this.noSleep.enable(); 
            }
        },

        release: function () {
            if(this.acquired)
            {
                this.acquired = false;
                this.noSleep.disable();
            }
        },

        toggle: function() {
            if(this.acquired)
                this.release();
            else
                this.acquire();
        }
    },

    beforeDestroy: async function() { 
    }
}
</script>

<style>

</style>