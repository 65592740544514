<template>
    <span class="px-2 badge text-dark align-text-bottom">
        <img class="icon" v-if="powered && active"  src="../assets/green_led_on.png"> 
        <img class="icon" v-if="powered && !active"  src="../assets/green_led_off.png"> 

        <img class="icon" v-if="!powered && active"  src="../assets/red_led_on.png"> 
        <img class="icon" v-if="!powered && !active"  src="../assets/red_led_off.png"> 
         {{ text }}
    </span>
</template>

<script>
export default {
    props: {
        text: { required: true, type: String },
        powered: { require: true, type: Boolean },
        active: { require: true, type: Boolean }
    },
  
}
</script>

<style scoped>
.icon {
    height: 16px;
    width: auto;
}
</style>