<template>
    <div> 
        <!-- Form di ricerca -->
        <form v-if="!cerca" class="d-flex justify-content-evenly flex-wrap"> 
                <div> 
                    <label for="dtinizio">Data di inizio</label>
                    <input name="dtinizio" type="datetime-local" 
						class="form-control form-control-sm" v-model="min_date" 
						placeholder="Data del primo punto">
                 </div>
                <div> 
                    <label for="dtfine">Data di fine</label>
                    <input name="dtfine" type="datetime-local" 
						class="form-control form-control-sm" v-model="max_date" 
						placeholder="Data dell'ultimo punto">
                </div>
                <div>
                    <button type="submit" @click.prevent="avvia_ricerca" class="mt-3 btn btn-primary">Cerca</button>
                </div> 
        </form> 

        <history-box :device="device" v-if="cerca"
            :min_date="min_date"
            :max_date="max_date"
            @request-close="on_request_close" 
            @on-points='on_points'
            @on-paths='on_paths'
            @on-current-point='on_current_point' />
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import HistoryBox from './HistoryBox.vue';
import { DateTime } from "luxon";

export default {
  components: { HistoryBox },
    props: {
        device: { required: true },
    }, 

    data: function( ) {
        var dt = DateTime.now().plus( { days: -7 });
        var dt2 = DateTime.now();
        return {
            cerca: false,
            min_date: dt.toISODate() + "T" + dt.toISOTime().substr(0,5),
            max_date: dt2.toISODate() + "T" + dt2.toISOTime().substr(0,5),
        }
    },

    methods: {
		
        avvia_ricerca: function() {
            var min_date = this.min_date;
            var max_date = this.max_date; 
            if(max_date < min_date)
            {
                Swal.fire({
                    text: "Il periodo richiesto non è valido. Controlla che la data di inizio sia antecedente a quella di fine.",
                    icon: 'error'
                });
                return;
            }
			this.cerca = true;
        },

        on_request_close: function() {
            if(this.cerca)
            {
                this.cerca = false;
                return;
            }
            this.$emit('request-close');
        },

        // Propaga l'evento
        on_paths: function(paths)
        {
            // Richiediamo il punto all'esterno
            this.$emit('on-paths', paths);
        },

        // Propaga l'evento
        on_points: function(points)
        {
            // Richiediamo il punto all'esterno
            this.$emit('on-points', points);
        },

        // Propaga l'evento
        on_current_point: function(point)
        {
            // Richiediamo il punto all'esterno
            this.$emit('on-current-point', point);
        }
    }
}
</script>
 