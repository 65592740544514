<template>
    <span class="p-2 badge bg-secondary streetName" :style="color" >
        {{ displayName }}
    </span>
</template>

<script>
export default {
    props: {
        hint: { required: true },
        lat: { required: true },
        lng: { required: false }
    },

    data: function() {
        return {
            timer: null,
            lastLat: null,
            lastLng: null,
            lastCheck: null,
            streetName: "",
            coord_changed: false,
            in_error: false,
        }
    },

    computed: {
        displayName: function() {
            if(this.streetName)
                return this.streetName;
            else
                return "STRADA SCONOSCIUTA"
        },

        color: function() { 
            if(this.in_error || this.streetName == "")
                return "color: red";
            return "";
        }
    },

    mounted: function( )  {
        // Ogni secondo aggiorniamo la strada
        this.timer = setInterval(this.check_street_name, 50);
    },

    beforeDestroy: function() {
        clearInterval(this.timer);
    },


    methods: {

        check_street_name: function() {
            this.coord_changed = this.lastLat != this.lat && this.lastLng != this.lng;
            if(this.lastCheck == null && this.lat != null)
                this.coord_changed = true;

            if(this.coord_changed && this.lat != 0 && this.lng != 0) {
                this.lastLat = this.lat;
                this.lastLng = this.lng;
                
                // Avvia il download del nome della strada
                this.download_street_name();
            }
        },

        download_street_name: async function() {
			if(this.lat == undefined || this.lng == undefined)
				return;
			if(isNaN(this.lat) || isNaN(this.lng))
				return;

            var key = "street" + this.lat + ";" + this.lng;
            var cached = window.dpixel_session.getItem(key);
            if(cached != null)
            {
                // Aggiorna il nome della strada
                cached = JSON.parse(cached);
                this.streetName = cached.addr;  
                
                // Emit
                this.$emit('on-street-name', cached);

                // Esci dal metodo
                return;
            } 

            // Può fare la richiesta?
            if(this.lastCheck != null)
            {
                // Quanti secondi sono passati?
                var dif = (new Date()).getTime() - this.lastCheck;
                var secs = dif / 1000;
                if(secs < 1)
                    return;
            }

            // Salva il check
            this.lastCheck = new Date();

            // Generiamo la risposta
            var ret = {
                lat: this.lat,
                lng: this.lng, 
                addr: '',
                found: false,
            };
            
            // Facciamo la richiesta
            console.log("Requesting street name ... ");
            var request = await this.$http.post("geodecode", {
                lat: (this.lat+"").replace(".",","),
				lng: (this.lng+"").replace(".",",")
			});

            // OK?
            if(request.status == 200)
            {
                // Decoded
                ret = request.data;
 
                // Cache della risposta
                window.dpixel_session.setItem(key, JSON.stringify(ret));
                
                // setup del nome da mostrare
                this.streetName = ret.addr; 
            }
            this.in_error = !ret.found;

            // Se siamo qui ci sono errori
            this.$emit('on-street-name', ret);
        }
    }
}
</script>

<style scoped>
div.streetName { 
    overflow: hidden;
    width: 100%; height: 48px;
}
</style>