<template>
    <div class="topbar d-flex flex-row">
        <slot></slot>
    </div>
</template>

<script>

 
export default {  
    props: { 
    }
}
</script>
    
<style local>

.topbar
{
    display: inline-block;
    width: 100%;
    background-color: lightgray;
    border-bottom: 1px solid gray;
    height: 28px;
    line-height: 28px; 
	overflow: hidden;
}

</style>