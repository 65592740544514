<template>
    <led :active="active" :powered="powered" text="Com." />
</template>

<script>
import Led from './Led.vue';
export default {
    components: { Led },

    data: function() {
        return {
            active: false,
            powered: true,
            request_interceptor: null,
        }
    },

    mounted: function() {
        var vm = this;

		// Add a request interceptor
		this.request_interceptor = this.$http.interceptors.request.use(function (config) {
            vm.active = true;
            vm.powered = true;
			return config;
		}, function(error) {
            console.log(error);
            vm.active = false;
            vm.powered = false;
        });
        
		// Add a request interceptor
		this.response_interceptor = this.$http.interceptors.response.use(function (response) {
            vm.active = false;
            return response;
		}, function(error) {
            console.log(error);
            vm.active = false;
        });
    },

    destroyed: function() {
        this.$http.interceptors.request.eject(this.request_interceptor);
        this.$http.interceptors.request.eject(this.response_interceptor);
    },
 
}
</script>