<template>
	<div>
		<!-- Top bar -->
		<top-bar v-if="logged != null" >
			<RTE :key="current_device.id" :device_id="current_device.id" v-if="current_device != null" />
			<communication-led />
			<led :active="is_routing" :powered="my_position != null" text="Routing" />
			<div class="flex-fill"> 
			</div>
			<screen-lock />
			<span v-if="current_device != null && current_device.device != null" class="px-2 badge text-dark align-text-bottom" style="cursor: pointer" @click="cambia">
			{{ current_device.device.nome }}
			<img class="img" src="../assets/device.png">
			</span> 
			<!-- Stop tracking -->
			<img src="../assets/refresh.png" style="cursor: pointer"  @click.prevent="refresh_page">

			<current-user :user="logged.user" />
		</top-bar>
		
		<!-- Mappa principale -->
		<GmapMap
			ref="mapRef"
			:center="map_center"
			:zoom="zoom"
			:options="mapOptions"
			:tilt="25" 
			map-type-id="roadmap"
			class="mapViewport" :class="track_mode == 'history' ? 'not-full' : 'full'"> 
			
			<my-location 
				@my-position="on_my_position" 
				@on-degree="on_my_degree"
			/>

			<template v-if="track_mode == 'autotrack' || track_mode == 'routing'">
				<line-renderer ref="lines" v-for="p in track_paths" :key="p.id"  :path='p' :index='p.id'  />
			</template>

			<template v-if="track_mode == 'history'">
				<line-renderer ref="historyLines" v-for="p in history_paths_to_show" :key="p.id"  :path='p' :index='p.id'  />
			</template>

			<device-marker v-if="current_device != null"  ref="deviceMarker"
				:device="current_device" 
				:mode="track_mode"
				@active_path_id="on_active_path_id"
				@position="on_device_position"
				@on-paths="on_track_paths"
			/>

			<d-router ref="drouter" v-if="gMap != null && my_position != null" 
				:enabled="track_mode == 'routing'"
				:from_position="my_position" 
				:to_position="current_point" 
				@on-directions="on_directions"
				@on-instructions="on_instructions"
				/>

		</GmapMap>

		<!-- Menu opener -->
		<button class="shadow rounded btn btn-circle bg-white bg-gradient" v-if="!is_routing"
			@click.prevent="is_menu_opened = !is_menu_opened"
			style="position: absolute; top: 100px; left: 16px; width: 64px; height: 64px">
			<svg viewBox="0 0 100 80" width="24" height="24" class="mt-1 text-black">
				<rect width="100" height="12" style="fill:black"></rect>
				<rect y="30" width="100" height="12" style="fill:black"></rect>
				<rect y="60" width="100" height="12" style="fill:black"></rect>
			</svg>
		</button>


		<!-- Debug -->
		<div class="shadow rounded btn btn-circle bg-white bg-gradient"  v-if="false"
			style="position: absolute; top: 100px; right: 16px; width: 64px; height: 64px; text-align: left;">
			F{{ focus }}<br>
			<small>D{{ new Number(degree).toFixed(2) }}</small>
		</div>

		<!-- Stop tracking -->
		<button class="shadow rounded btn btn-circle bg-white bg-gradient" v-if="is_routing"
			@click.prevent="toggle_routing"
			style="position: absolute; top: 100px; left: 16px; width: 64px; height: 64px">
			STOP
		</button>
		
		<!-- Zoom su macchina -->
		<button class="shadow rounded btn btn-circle bg-white bg-gradient" v-if="is_routing"
			@click.prevent="set_focus(1)"
			style="position: absolute; top: calc(100px + 64px + 8px); left: 16px; width: 64px; height: 64px">
			<img src="../assets/car.png" />
		</button>

		<!-- Zoom su veicolo -->
		<button class="shadow rounded btn btn-circle bg-white bg-gradient" v-if="is_routing"
			@click.prevent="set_focus(2)"
			style="position: absolute; top: calc(100px + 64px * 2 + 8px * 2); left: 16px; width: 64px; height: 64px">
			<img :src="$refs.deviceMarker.icon.url" />
		</button>

		<!-- Direzioni -->
		<button class="shadow rounded btn btn-circle bg-white bg-gradient" v-if="is_routing"
			@click="direzioni = !direzioni"
			style="position: absolute; top: calc(100px + 64px * 3 + 8px * 3); left: 16px; min-width: 64px; min-height: 64px">
			-{{ format_distanza_unit(distanza) }} <br>
			{{ format_distanza(distanza) }}
		</button>

		<!-- History controller ---> 
		<div v-show="track_mode == 'history'"
			class="bottom-bar shadow bg-gradient bg-secondary text-white " style="height: 200px">
			<history-controller 
				@request-close="close_history"
				@on-current-point="on_request_position"
				@on-paths="on_history_paths"
				v-show="current_device != null"  
				:device="current_device" />
		</div> 

		<!-- Track mode -->
		<div v-show="track_mode != 'history'"
			class="shadow bg-gradient bg-secondary text-white pt-2 bottom-bar">
			<point-label :point="current_point" :gtm='false' />
		</div>

		<!-- Menù principale -->
		<div v-show="is_menu_opened" class="shadow bg-white p-4" 
			style="position: absolute; top: 48px; top: 100px; left: 100px; right: 16px; max-width: 500px" >
			<div class="d-grid gap-2">
				<span v-if="current_device != null && current_device.device != null"> {{ current_device.device.nome }} </span>
				<button @click.prevent="set_track_mode( track_mode == 'history' ? 'autotrack' : 'history' )" class="btn btn-primary" type="button">
					{{ track_mode == 'history' ? 'Modalità track' : 'Modalità storico' }}
				</button>
				<button v-if="my_position != null" @click.prevent="toggle_routing" class="btn btn-primary" type="button">
					Avvia inseguimento
				</button>
			</div>
		</div>

		
		<!-- Istruzioni -->
		<div class="shadow rounded btn btn-circle bg-white bg-gradient" v-show="direzioni && is_routing"
		style="overflow: scroll; position: absolute; top: 64px; right: 0; width: 240px; bottom: 200px">
			<div v-if="latest_instructions.length == 0">
				Informazioni non ancora disponibili
			</div>
			<div v-else>
				<p v-if="false">Distanza: {{ distanza }} Km</p>
				<p v-for="i in latest_instructions" :key="i.encoded_lat_lngs" v-html="i.instructions">
				</p>
			</div>
		</div>


		<!-- Fullscreen loader -->
		<div v-if="loading" class="pt-4" style="position: absolute; top: 0px; left: 0px; background: white; bottom: 0px; right: 0px">
			
            <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div><br>

			<span v-if="current_point == null && gMap != null">
				In attesa di una prima posizione ...
				<br>
				<br>
				<button class="btn btn-primary" @click="cambia">Cambia dispositivo</button>
			</span>
			<span v-else>
				Avvio applicazione in corso, attendere ...
			</span>
		</div>
	</div>
</template>

<script>    
import Vue from 'vue';
import RTE from '../components/RTE.vue';
import DeviceMarker from '../components/DeviceMarker.vue';
import TopBar from '../components/TopBar.vue';
import CurrentUser from '../components/CurrentUser.vue';
import Led from '../components/Led.vue';
import CommunicationLed from '../components/CommunicationLed.vue';
import HistoryController from '../components/HistoryController.vue';   
import LineRenderer from '../components/LineRenderer.vue';
import MyLocation from '../components/MyLocation.vue';
import PointLabel from '../components/PointLabel.vue';
import DRouter from '../components/DRouter.vue';
import Swal from 'sweetalert2';
import ScreenLock from '../components/ScreenLock.vue';

import { distanceTo } from 'geolocation-utils' 

export default { 
	name: 'Dashboard',  
	components: { RTE, TopBar, DeviceMarker,CurrentUser,Led,CommunicationLed, HistoryController, LineRenderer, MyLocation, PointLabel, DRouter, ScreenLock },
	data: function() {
		return {
			// Utente loggato
			logged: null,
			track_mode: '',
			degree: 90,

			// Dispositivi
			devices: [],
			current_device: null,
			current_point: null,
			my_position: null,

			// Dati sulla mappa
			gMap: null,
			zoom: 18,
			center: {lat: 40.7612626, lng: 14.7870203 },
			map_center: {lat: 40.7612626, lng: 14.7870203 },
			mapOptions: {
				mapId: '4e94e80c99c907b9',
				gestureHandling: "cooperative",
				draggable: true, 
				zoomControl: true,
			},
			focus: null,

			// Percorsi da mostrare
			track_paths: [],
			instructions: [],
			latest_instructions: [],
			distanza: 0,
			direzioni: false,

			// Percorsi da mostrare dalla history
			history_paths: [],
			history_paths_to_show: [],

			// Path id attivo
			active_path_id: null,

			// UI e stato app
			loading: true,
			is_menu_opened: false,

			// Maps ready?
			map_ready: false,
		}
	}, 

	created: async function() {

		// Siamo già loggati?
		var login = window.dpixel_session.getItem("login");
		if(login !== null)
		{
			var response = JSON.parse(login);  
			this.$http.defaults.headers.common['Authorization'] = response.auth;
			this.logged = response; 
			Vue.mixin({
				computed: {
					current_login_data: function() { 
						return response
					}
				}
			});
			
			this.current_device = window.dpixel_session.getItem('current_device');

			if(this.current_device == null)
				this.$router.push('/');
			else
				this.current_device = JSON.parse(this.current_device);		 
	
			// Imposta il devices da mostrare
			this.set_active_device(this.current_device);

			// Facciamo una richiesta per capire se siamo loggati
			try
			{
				await this.$http.get("positionsbytime/" + this.current_device.id + "/last/1");  
			}
			catch(er)
			{
				// Sessione scaduta?
				if(er.request.status == 403)
				{
					await Swal.fire({
						icon: 'warning',
						title: 'La tua sessione è scaduta.',
						confirmButtonText: "OK",
					});
					window.dpixel_session.clear();
					window.location.reload();
				}
			}
		}
		else
		{
			this.$router.push('/');
		}
	},

	mounted: function() { 
		// Attendiamo la mappa
		var vm = this;

		this.map_ready = setInterval(function() {
 
			// Non abbiamo ancora la mappa
			if(vm.$refs.mapRef === undefined || vm.$refs.mapRef === null)
			{
				console.log("Attendo la mappa ... ");
				return;
			}
			if(vm.$refs.mapRef.$mapPromise === undefined || vm.$refs.mapRef.$mapPromise === null)
			{
				console.log("Attendo la mappa, parte 2 ... ");
				return;
			}

			// Clear
			clearInterval(vm.map_ready);
			console.log("Attendo la mappa, parte 3 ", vm.center);
	
			vm.$refs.mapRef.$mapPromise.then((map) => {
				vm.gMap = map; 
				window.gMap = map;
				vm.set_track_mode('autotrack');
			});
		}, 1000);
	},

	computed: {
		is_routing: function() {
			return this.track_mode == 'routing';
		},
	},

	watch: {
		focus: function() {
			this.update_focus();
		}
	},

	methods: { 
		format_distanza: function(distanza) {
			if(!isNaN(distanza))
			{
				var n = new Number(distanza).toFixed(2);
				return n;

				/*
				if(n > 0)
				{
					if(distanza < 1 && distanza >= 0)
						return new Number(distanza).toFixed(0);
					else
						return n;
				}
				*/
			}
			return "--";
		},
		
		format_distanza_unit: function(distanza) {

			// Evitiamo errori
			if(!isNaN(distanza))
				return "--";

			/*
			if(!isNaN(distanza))
			{
				if(distanza < 1 && distanza >= 0)
					return "Mt";
			}*/
			return "Km";
		},

		cambia: async function() {
			var result = await Swal.fire({
				icon: 'question',
				title: 'Vuoi davvero cambiare dispositivo? Perderai la sessione attuale',
				showCancelButton: true,
				confirmButtonText: "Cambia",
				cancelButtonText: "Non cambiare",
			});

			// Termina la sessione
			if(result.isConfirmed)
			{ 
				window.dpixel_session.removeItem("current_device");
				this.$router.push('/');
			}
		},

		set_focus: function(f) {
			this.focus = f;
			this.update_focus();
		},

		on_directions: function() {
			if(this.gMap != null) {
				this.update_focus();
				this.update_heading(this.degree); 
			}
		},

		on_instructions: function(instructions) { 
			this.instructions = instructions; 
			this.update_insturctions(true);
		},

		update_insturctions: function(full) {
			// Debo aggiornare tutto e stop
			var i = 0;
			if(full)
			{
				this.latest_instructions = [];
				for(i = 0; i < this.instructions.length; i++)
					this.latest_instructions.push(this.instructions[i]);
			}

			// Siamo vicini al punto?
			while(this.latest_instructions.length > 0)
			{
				var p = this.latest_instructions[0].start_location;
				var me = this.my_position;

				if(me != null && p != null)
				{
					var distance = distanceTo(me, { lat: p.lat(), lng: p.lng() });
					if(distance <= 0.01) 
					{
						this.latest_instructions.pop();  
						continue;
					}
				}

				// Usciamo dal ciclo
				break;
			}

			// Calcoliamo la distanz
			this.distanza = 0;
			for(i = 0; i < this.latest_instructions.length; i++)
			{
				if(this.latest_instructions[i].distance && this.latest_instructions[i].distance.value)
					this.distanza += this.latest_instructions[i].distance.value;
			}
			this.distanza *= 0.001;
		},

		on_my_degree: function(degree) { 
			console.log("INCOMING degree", degree); 
			this.degree = degree; 
			this.update_heading(this.degree); 
		},

		on_my_position: function(position) { 
			if(this.my_position == null)
			{
				this.my_position = position;
			}
			else
			{ 
				this.my_position.lat = position.lat;
				this.my_position.lng = position.lng;
			}

			if(this.focus == 1)
				this.update_focus();

			// Aggiorna le istruzioni se possibile
			this.update_insturctions(false);
		},

		update_focus: function( ) {
			if(this.focus == 1 && this.my_position != null)
			{
				if(this.gMap != null)
					this.gMap.panTo(this.my_position);
				this.update_heading(this.degree);
			}
			else if(this.focus == 2 && this.current_point != null)
			{
				if(this.gMap != null)
					this.gMap.panTo(this.current_point);
				this.update_heading(this.degree);
			}
		},

		refresh_page: function() {
			window.location.reload();
		},

		toggle_routing: function() {
			if(this.track_mode == 'routing')
				this.set_track_mode('autotrack');
			else
				this.set_track_mode('routing');
			this.is_menu_opened = false;
		},

		// Alla chiusura dell'history box
		close_history: function() {
			this.set_track_mode('autotrack');
		},

		// Ricevute tutte le posizioni dallo storico
		on_history_paths: function(paths) {
			this.history_paths = paths;
		},


		// Ricevute tutte le posizioni dallo storico
		on_track_paths: function(paths) {
			this.track_paths = paths; 
			this.update_track_backlog();
		},

		// Imposta il device da mostrare
		set_active_device: function(device) {
			// Esiste già?
			this.current_device = {
				id: device.id,
				device: device,
				points: [],
				position: null
			};
		},
		

        /**
         * Cambia modalità
         */
        set_track_mode: function(mode) {
            this.track_mode = mode;
            if(mode == 'history')
            {
                // Gestirà tutto il controller della history ma bisogna disabilitare l'autotrack
				this.focus = 0;
				if(this.gMap != null && this.current_point != null)
					this.gMap.panTo(this.current_point);
            }
            else if(mode == 'autotrack' || mode == "routing")
            {
				// Andiamo al punto
				if(mode == 'routing')
					this.focus = 1;
				else
					this.focus = 2;
					
				if(this.gMap != null && this.current_point != null)
					this.gMap.panTo(this.current_point);
            }

			// Chiudi sempre il menù
			this.is_menu_opened = false;
        },

		// Richiede l'impostazione dell'angolatura della mappa
		update_heading: function(degree)
		{
			if(this.track_mode == 'routing')
			{
				if(this.gMap != null)
					this.gMap.setHeading(degree);
			}
		},

		/**
		 * Evento lanciato dal device. Notifica la sua posizione
		 */
		on_device_position: function(position) {  
			if(position == null)
				return null;

			var changed = (this.center.lat != position.lat || this.center.lng != position.lng);
			this.current_point = position;

			if(this.loading)
			{
				this.center.lat = position.lat;
				this.center.lng = position.lng;
				this.loading = false; 
			}

			// Auto center?
			if(this.focus == 2 && changed)
			{
				this.update_heading(this.degree);
				this.update_focus();
			}
		},

		// Il device segnala un path id
		on_active_path_id: function (active_path_id) {
			this.active_path_id = active_path_id; 

			if(this.track_mode == 'history')
			{
				// Aggiorniamo i percorsi da mostrare
				var history_paths_to_show = [];

				// Cerchiamo i percorsi
				if(this.active_path_id != null)
				for(var i = 0; i < this.history_paths.length; i++)
				{
					if( Math.abs( this.history_paths[i].id - this.active_path_id) < 5 )
						history_paths_to_show.push(this.history_paths[i]);	
				} 
				this.history_paths_to_show = history_paths_to_show;
				this.update_track_backlog();
			} 
		},

		/**
		 * Evento per richiedere la posizione al dispositivo
		 */
		on_request_position: function(position) {
			// Richiede la posizione
			this.$refs.deviceMarker.request_position(position);
			this.set_focus(2);
		}, 

		/**
		 * Ridisegna le linee
		 */
		update_track_backlog: function() {
			var vm = this;
			if(this.$refs.lines)
			{ 
				this.$nextTick(()=> {
					vm.$refs.lines.forEach(l => { 
						l.refresh();
					});
				})
			}
			if(this.$refs.historyLines)
			{ 
				this.$nextTick(()=> {
					vm.$refs.historyLines.forEach(l => { 
						l.refresh();
					});
				})
			}
		}

	}
}
</script>

<style scoped>
	.mapViewport {
		position: absolute;
		left: 0px;
		top: 30px;
		right: 0px;
		bottom: 100px;
	}

	.mapViewport.not-full { 
		bottom: 200px;
	}

	.mapViewport.full { 
	}

	.bottom-bar {
		position: absolute;
		bottom: 0px;
		left: 0px;
		right: 0px;
		height: 100px;
	}
</style>