<template>
    <GmapMarker v-if="last != null"
        :position="position"
        :clickable="false"
        :draggable="false"
        :icon="icon"
    />
</template>

<script>
import { headingDistanceTo } from 'geolocation-utils'
export default {
    props: {
        device: { type: Object, required: true },
        mode: { type: String, default: 'autotrack', required: false },
    },

    data: function() {
        return { 
            interval: null,
            points: new Map(),
            last: null,
            last_dt: null,
            position: {lat: 0, lng: 0 },
            downloading_data: false,
            speed: 0,
            posDate: new Date(),
            autotrack: true,
            active_path_id: null,
            paths: [],
            icon: {
                url: require('../assets/car.png'),
                size: {height: 32, width: 32},
                origin: { x: 0, y: 0 },
                anchor: { x: 16, y: 16 },
                scaledSize: {height: 32, width: 32},
            },
            icons: {
                active : require('../assets/active.png'),
                notactive : require('../assets/notactive.png') ,
                stop : require('../assets/stop.png'),
            }
        }
    },
  
    mounted: function() {
        // Scarichiamo l'ultimo punto
        this.update_last(); 
        window.on_remote_points = this.rte_on_remote_points;
        var vm = this;
        this.interval = setInterval(() => {
            vm.update_icon();
        }, 1000);
    },
    
    beforeDestroy: function() {
        if(this.interval != null)
        {
            clearInterval(this.interval);
            this.interval = null;
        }
    },

    computed: {
        id: function() {
            return this.device.id;
        },
    },

    watch: {
        mode: function() { 
            if(this.mode == 'autotrack' || this.mode == 'routing')
                this.set_autotrack(true);
            else
                this.set_autotrack(false);
        }
    },

    methods: {

        rte_on_remote_points: function(device_id, points)
        {
            if(device_id == this.id)
                this.merge_points(points);
        },

        set_current_point: function(p) {
            if(p == null)
                return;

            // Calcola la distanza dall'ultimo punto
            var df = null;
            if(this.position != null)
            {
                df = headingDistanceTo(
                        { lat: this.position.lat, lon: this.position.lng }, 
                        { lat: p.lat, lon: p.lng } 
                );

                // Ogni 5 metri aggiorniamo i path
                if(df.distance >= 1)
                {
                    df = null;
                }
            }

            // Compute dei path
            if(df == null && ( this.mode == 'autotrack' || this.mode == 'routing') )
                this.paths = this.compute_path();

            // Impostiamo la posizione
            this.speed = p.speed;
            this.posDate = p.posDate;
            if(this.position == null)
                this.position = { lat: p.lat, lng: p.lng };
            else
            {
                this.position.lat = p.lat;
                this.position.lng = p.lng; 
            }

            // Aggiorniamo la posizione
            if(p.path_id !== undefined)
                this.active_path_id = p.path_id;
            else
                this.active_path_id = null;

            if(df == null && ( this.mode == 'autotrack' || this.mode == 'routing') )
                this.$emit('on-paths', this.paths); 
            this.$emit("position", p);  
            this.$emit("active_path_id", this.active_path_id);
            this.update_icon();
        },

        /**
         * Aggiorna l'icona
         */
        update_icon: function() {
            // Quanti secondi sono passati?
            var url = "";
            var now = new Date().getTime();
            var posDate = new Date( this.posDate ).getTime(); 
            var diff = now - posDate;
            var secs = diff / 1000; 
            
            if(this.speed == 0)
                url = this.icons.stop;
            else
            {
                if(secs <= 20)
                    url = this.icons.active;
                else
                    url = this.icons.notactive;  
            }

            this.icon = {
                url: url,
                size: {height: 32, width: 32},
                origin: { x: 0, y: 0 },
                anchor: { x: 16, y: 16 },
                scaledSize: {height: 32, width: 32},
            };
        },


        // Computa i percorsi fatti e assegna ad ogni punto il proprio percorso
        compute_path: function() {
            var paths = [];
 
			// Torniamo indietro con i punti 
            var raw_points = [];
            this.points.forEach(x => {
                this.points.get(x.id).path_id = undefined;
                raw_points.push(x);
            });

            raw_points.sort(function(a,b) {
                var dtA = new Date( b.posDate ).getTime();
                var dtB = new Date( a.posDate ).getTime();
                if(dtA < dtB)
                    return -1;
                else if(dtB > dtA)
                    return 1;
                else
                    return 0;
            });


			var path = [];
			for(var i = raw_points.length - 1; i >= 0; i--)
			{  
				// Accodiamo il punto
                raw_points[i].path_id = paths.length;
                this.points.get(raw_points[i].id).path_id = paths.length;

                // Aggiungiamolo al percorso
				path.push(this.points.get(raw_points[i].id) ); 
			}
            paths.push( { id: paths.length,  positions: path } );
            return paths; 
        },

        // Effettuiamo il merge dei punti in questione
        merge_points: function(points) {
            var vm = this;
            var fistTime = vm.points.length == 0;

            points.forEach(p => { 
                vm.points.set(p.id, p);
                
                if(vm.last == null)
                {
                    vm.last = p;
                    vm.last_dt = new Date( p.posDate );
                }
                else
                {
                    var dtP = new Date( p.posDate ).getTime();
                    var dtT = new Date( vm.last.posDate ).getTime();  
                    
                    if(dtP >= dtT)
                    {
                        vm.last = p;
                        vm.last_dt = new Date( p.posDate );
                    }
                }
            });

            // Adesso impostiamo il punto da mostrare se stiamo facendo l'autotrack
            if(this.autotrack || fistTime) 
                if(this.mode == 'routing' || this.mode == 'autotrack')
                    this.set_current_point(this.last); 
        },

        format_date: function(dt) {
            var d =  dt.getFullYear() + "-" 
                    + (1 + dt.getMonth()  < 10 ? "0" + (1 + dt.getMonth()) : (1 + dt.getMonth())) + "-" 
                    + ( dt.getDate() < 10 ? "0" + dt.getDate() : ""+dt.getDate()) ;
            
            var h = dt.getHours() >= 10 ? dt.getHours() : "0" + dt.getHours();
            var m = dt.getMinutes() >= 10 ? dt.getMinutes() : "0" + dt.getMinutes();
            var s = dt.getSeconds() >= 10 ? dt.getSeconds() : "0" + dt.getSeconds();

            return d + " " + h + ":" + m + ":" + s;

        },

        // Scarica dei dati
        download_data: async function(min_datetime, howmany) {
            if(this.downloading_data)
            {
                console.log("Avoid downloading too much data");
                return;
            }

            this.downloading_data = true;
            try
            {
                // Facciamo la richiesta
                var d = "last"; // min_datetime == null ? "last" : this.format_date(min_datetime);

                console.log("Sto richiedendo il last " + d);
                var request = await this.$http.get("positionsbytime/" + this.id + "/" + d + "/" + howmany);  
                if(request.status != 200)
                {
                    // Non procediamo
                    return;
                }

                // Otteniamo la risposta  
                this.merge_points(request.data);
            }
            finally
            {
                this.downloading_data = false;
            }
        },

        /**
         * Richiede la posizione
         */
        request_position: function(point) {
            this.set_current_point(point);
        },

        /**
         * Andiamo all'ultimo punto ricevuto
         */
        goto_last: function()
        {
            this.set_current_point(this.last);
        },

        /**
         * Imposta l'autotrack
         */
        set_autotrack: function(state) {
            
            if(state)
            {
                this.goto_last();
                this.start_autotrack();
            }
            else
                this.stop_autotrack();

            // Aggiorna lo stato
            this.autotrack = state;
        },

        start_autotrack: function() {
            this.autotrack_timeout = setTimeout(this.update_last, 1000);
        },

        stop_autotrack: function() {
            if(this.autotrack_timeout != 0)
                clearTimeout(this.autotrack_timeout);
        },

        /**
         * Scarica l'ultimo punto per questo
         */
        update_last: async function() { 

            // Aggiorna l'icona
            this.update_icon();

            // Scarica il prossimo punto 
            await this.download_data( this.last_dt , 30); 

            // Auto track
            if(this.autotrack)
            {
                // Continua!
                // this.start_autotrack();
            }

        }

    }
}
</script>

<style>

</style>