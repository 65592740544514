<template>
    <gmap-polyline  
        :path="paths"
        :editable="false" 
        :draggable='false' 
        :options='options' />
</template>

<script>
import { headingDistanceTo } from 'geolocation-utils';
export default {
    props: { 
        path: { required: true },
        index: { required: true }, 
    },

    data: function() {
        return {
            options: {
                strokeColor: this.get_color(),
                strokeOpacity : 0.5,
            },

            // Punti da renderizzare
			paths: [
				// [ {lat: 1.380, lng: 103.800}, {lat:1.380, lng: 103.810} ],
			],

        }
    },

    mounted: function() {
        this.refresh();
    },

    methods: {
        refresh: function() {
            this.set_points(this.path.positions);
        },

        get_color: function() {
            var colors = [
                "#FF0000",
                "#0000FF",
                "#00FF00", 
                "#FF00FF",
                "#00FFFF",
                "#0F0F0F",
            ]
            return colors[this.index % colors.length];
        },

        set_points: function(points) {
            var paths = [];
            var latest = null;
            for(var i = 0; i < points.length; i++)
            {
                // Renderizziamo il percorso
                if(latest != null)
                {
                    if(latest.lat == points[i].lat && latest.lng == points[i].lng)
                    {
                        continue;
                    }

                    var df = headingDistanceTo(
                            { lat: latest.lat, lon: latest.lng }, 
                            { lat: points[i].lat, lon: points[i].lng } 
                    );

                    // Troppo vicini
                    if(df.distance <= 3 ){
                        continue;
                    }
                }
                latest = points[i];
                paths.push( {lat: points[i].lat, lng: points[i].lng} )
            }
            this.paths = paths;  
        }
    }
}
</script>