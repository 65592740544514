<template>
	<div class="login">
		<img alt="dpixel" src="../assets/logo.png" class="mb-2">
		<p class="mb-4">Versione 03042024 1115</p>

		<div class="container page" v-show="state == 0">
			<div class="row">
				<div class="col-md-4 offset-md-4">
					<form @submit.prevent="login">
						<div class="mb-3">
							<label for="username" class="form-label">Username</label>
							<input maxlength="32" v-model="username" type="text" class="form-control" id="username" placeholder="Il tuo nome utente">
						</div> 
						<div class="mb-3">
							<label for="password" class="form-label">Password</label>
							<input maxlength="32" v-model="password" type="password" class="form-control" id="password" placeholder="La tua password">
						</div> 
						<button type="submit" class="btn btn-outline-primary" >Invia</button>
					</form>
				</div> 
			</div> 
		</div>
		
		<div class="container page" v-show="state == 1">
			<div class="row">
				<div class="col-md-4 offset-md-4">
					<p>I dati inseriti non sono validi</p>
					<button type="button" class="mt-4 btn btn-outline-danger" @click.prevent="state = 0">Torna al login</button>
				</div>
			</div>
		</div>

		<div class="container page" v-show="state == 3">
			<div class="row">
				<div class="col-md-4 offset-md-4">
					<p>Errore di comunicazione</p>
					<button type="button" class="mt-4 btn btn-outline-danger" @click.prevent="state = 0">Torna al login</button>
				</div>
			</div>
		</div>

		<div class="container page" v-if="state == 2">
			<p>Benvenuto <b>{{ userData.username }}</b>!</p> 
			<div class="row">
				<div class="col-md-4 offset-md-4">
					<div class="d-grid gap-2">
						<button type="button" @click="goto(d)" class="btn btn-outline-primary" v-for="d in devices" :key="d.id">
							{{ d.nome }}
						</button>
					</div>
					<button type="button" class="mt-4 btn btn-outline-danger" @click.prevent="logout">Logout</button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>  

export default {
	name: 'Login',
	data: function() {
		return {
			username: '',
			password: '',
			state: 0,
			authCode: '',
			userData: null,
			devices: []
		}
	},
	mounted: function() {
		// Siamo già loggati? 
		var login = window.dpixel_session.getItem("login"); 
		if(login != null)
		{
			var response = JSON.parse(login); 
			this.authCode = response.auth;
			this.userData = response.user;
			this.devices = response.devices;
			this.state = 2;

			var cc = window.dpixel_session.getItem("current_device");
			if(cc)
				this.$router.push('/dashboard');
		} 
	},

	methods: {
		logout: function() {
			window.dpixel_session.setItem("login", "" );
			this.state = 0;
		},

		goto: function(device) { 
			window.dpixel_session.setItem("current_device", JSON.stringify( device ));
			this.$router.push('/dashboard');
		},

		login: async function() { 
			// Effettua la richiesta
			var request = await this.$http.post( "login", { username: this.username, password: this.password } );
			if(request.status != 200)
			{ 
				this.state = 3;
				return;
			}
			var response = request.data; 
			if(response.error == -1)
			{
				this.state = 1;
			}
			else
			{
				// Salva la risposta
				window.dpixel_session.setItem("login", JSON.stringify(  response ) );
				this.$http.defaults.headers.common['Authorization'] = response.auth;

				this.authCode = response.auth;
				this.userData = response.user;
				this.devices = response.devices;
				this.state = 2;
			} 
		}
	}
}
</script>