<template>
    <div class="m-1">
        <!-- Loader -->
        <div class="d-flex align-items-center" v-if="is_loading">
            <strong>
                <span v-if="loading == 1 || loading == 0">
                    Apertura storico in corso, attendere... {{ progressStr }}
                </span>
                <span v-if="loading == 2">
                    Download punti dallo storico corso, attendere...
                </span>
            </strong>
            <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
        </div>

        <button v-if="in_error" @click="retry" class="btn btn-primary btn-danger">
            Riprova
        </button>

        <button v-if="history != null && history.totalPoints == 0 && is_ready" @click="close" class="btn btn-primary btn-danger">
            Non ci sono risultati
        </button>
 

        <!-- Dati reali -->
        <div v-if="history != null && history.totalPoints > 0 && is_ready">  
            <label for="history" class="form-label"> 
            </label>
            <input v-model="range_id" type="range" class="form-range" id="history"  :min="0" :max="history.totalPoints-1">

            <!-- Tasti di controllo -->
            <div class="btn-toolbar d-flex justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                     
				<div class="btn-group mr-2" role="group" >
				<button @click="toggle_play" type="button" class="btn btn-sm btn-light">
					{{ state == 0 ? 'Play' : 'Stop'}}
				</button> 

                    <button @click="set_anim_speed('b')" type="button" :class="anim_speed < 0 ? 'active' : ''" class="btn btn-sm btn-light">
                        &lt;
                    </button>
                    <button @click="set_anim_speed(1)" type="button"  :class="anim_speed == 1 || anim_speed == -1 ? 'active' : ''"  class="btn btn-sm btn-light">
					1x
                    </button>
                    <button @click="set_anim_speed(2)" type="button"  :class="anim_speed == 2 || anim_speed == -2 ? 'active' : ''"  class="btn btn-sm btn-light">
					2x
                    </button>
                    <button @click="set_anim_speed(4)" type="button" :class="anim_speed == 4 || anim_speed == -4 ? 'active' : ''" class="btn btn-sm btn-light">
					4x
                    </button>
                    <button @click="set_anim_speed('f')" type="button" :class="anim_speed > 0 ? 'active' : ''" class="btn btn-sm btn-light">
                        &gt;
                    </button>
                </div>     

                <div class="btn-group" role="group">
                    <span class="align-middle"> 
                    {{ (range_id*1)+1 }} / {{ history.totalPoints }}
                    </span>
                </div>

                <div class="btn-group" role="group" @click="close">
                    <button class="btn btn-sm btn-light">Esci</button>
                </div>
            </div> 
            <point-label class="mt-4" :gtm="false" :point="current_point" @on-street-name="on_street_name" />
        </div>

    </div>
</template>

<script>
import PointLabel from './PointLabel.vue';

export default {
  components: { PointLabel },
    props: {
        device: { required: true },
        min_date: { required: true },
        max_date: { required: true },
    },
    
    data: function() {
        return {
            anim_speed: 1,
            state: 0,
            loading: 0,
            range_id: 0,
            history: null,
            points: new Map(),
            current_point: null,
			progress_total: 0,
			progress_current: 0,
        }
    }, 

    computed: {
		progressStr: function() {
			if(this.progress_total == 0)
				return "";
			else
			{
				var perc = 100 * ( parseFloat(this.progress_current) / parseFloat( this.progress_total ) );
				return this.progress_current + "/" + this.progress_total + " ( " + new Number(perc).toFixed(0) + " %)";
			}
		},

        in_error: function () {
            return this.loading == -1;
        },
        is_loading: function() {
            return this.loading >= 0 && this.loading < 3;
        },
        is_ready: function() {
            return this.loading == 3;
        },
        point_id: function () { 
            if(this.history == null)
                return null;
            else
                return this.history.ids[this.range_id];
        }, 
    },

    mounted: function() {
        this.download_preview();
    },

    watch: {
        range_id: function() {
            
            // Aggiorna il punto
			console.log("Rage id updated: " + this.point_id);
            this.current_point = this.point_id == null ? null : this.points.get(this.point_id);

            // Richiediamo il punto
            this.$emit('on-current-point', this.current_point);
        }
    }, 

    methods: {

        /**
         * Nome della strada
         */
        on_street_name: function(result) { 
            if(result.found && this.current_point != null)
            { 
                if(this.current_point.lat == result.lat && this.current_point.lng == result.lng)
                {
                    this.current_point.streetName = result.addr;
                    this.$emit('on-street-name', result);
                }
            }
        },

        close: function() {
            // Ferma l'animazione
            this.play(0);

            // Richiedi la chiusura
            this.$emit('request-close');
        },

        /**
         * Velocità di anim
         */
        set_anim_speed: function(speed) {
           
			if(speed == 'f')
				this.anim_speed = Math.abs(this.anim_speed);
			else if(speed == 'b')
				this.anim_speed = -Math.abs(this.anim_speed);
			else
				this.anim_speed = this.anim_speed < 0 ? -Math.abs(speed) : Math.abs(speed);

            if(this.state != 0) // Solo se sono in play
            {
                if(this.animInterval != 0)
                    clearInterval(this.animInterval);
                this.animInterval = setInterval(this.anim_update_frame, Math.abs( 1000 / this.anim_speed ));
                console.log("ANIM; " + (1000 / this.anim_speed));
            }
        },

        // Tick sull'animazione per aggiornare un frame
        anim_update_frame: function() {
            if(this.anim_speed > 0)
            {
                if(this.range_id < this.history.totalPoints-1)
                    this.range_id++;
            }
            else
            {
                if(this.range_id > 0)
                    this.range_id--;
            }
        },

        // Inverte la riproduzione attuale
        toggle_play: function() {
            this.play(this.state == 0 ? 1 : 0);
        },

        // Imposta la riproduzione
        play: function(anim) {
            if(this.state != anim)
            {
                // Sta per andare in play?
				if(this.animInterval != 0)
                {
                    clearInterval(this.animInterval);
                    this.animInterval = 0;
                }
            }
            this.state = anim;
        },

        // Riavvia il download
        retry: function() {
             this.download_preview();
        },

        // Computa i percorsi fatti e assegna ad ogni punto il proprio percorso
        compute_path: function(raw_points) {
            var paths = [];
 
            raw_points.sort(function(a,b) {
                var dtA = new Date( b.posDate ).getTime();
                var dtB = new Date( a.posDate ).getTime();
                if(dtA < dtB)
                    return -1;
                else if(dtB > dtA)
                    return 1;
                else
                    return 0;
            });

			// Torniamo indietro con i punti 
			var path = [];
			for(var i = raw_points.length - 1; i >= 0; i--)
			{  
				// Accodiamo il punto
                raw_points[i].path_id = paths.length;

                // Aggiungiamolo al percorso
				path.push(raw_points[i]);
				if(raw_points[i].speed == 0)
				{ 
					// Andiamo avanti veloce al prossimo punto che ha una velocità
					for(; i >= 0; i--)
					{
                        // Nessun path
                        path.push(raw_points[i]);
                        raw_points[i].path_id = paths.length-1;

						if(raw_points[i].speed > 0)
						{
							i++;
							break;
						}
					}
                    
					// Salviamo il path trovato
					paths.push( { id: paths.length,  positions: path } );

                    // Nuovo path
					path = [];
				}
			}
            if(path.length > 0)
                paths.push( { id: paths.length,  positions: path } );
            return paths; 
        },

        // Scarica il preview dei dati
        download_preview: async function() {
            
			return await this.download_data_full();

/*
            // Facciamo la richiesta
            try
            {
                this.loading = 1;
                var request = await this.$http.get("history/" + this.device.id + "/" + this.min_date + "/" + this.max_date);  
                if(request.status == 200)
                {
                    // Aggiorniamo la history
                    this.history = request.data;

                    // Scarica i dati
                    if(this.history.totalPoints > 0)
                        await this.download_data();
                    else
                        this.loading = 3;

                } 
            }
            catch(err)
            {
                // Siamo in errore
                this.loading = -1;
            }*/
        },
        
        download_data: async function() {
            
            // Evitiamo lo spam di troppe richieste
            if(this.loading == 2)
                return;

            try
            {
                // Facciamo la richiesta per scaricare i punti reali
                this.loading = 2;
                var request = await this.$http.post("positions", {
                    deviceId: this.device.id,
                    ids: this.history.ids,
                });  
                if(request.status == 200)
                {
                    // Aggiorniamo la history
                    this.loading = 3;

                    // Generiamo i percorsi
                    const ppp = request.data;
                    this.points = new Map();
                    var paths = this.compute_path(ppp);

                    // Salviamo i punti
                    const vm = this;
                    ppp.forEach(pp => {
                        vm.points.set(pp.id, pp);
                    });
                    
                    // Aggiorna il punto
                    this.range_id = this.history.totalPoints-1;
                    this.$emit('on-points', request.data);
                    this.$emit('on-paths', paths);
                }
            }
            catch(err)
            {
                this.loading = -1;
            }
        },
		

		parsePoints: function(lines) {
			var ppp = [];
			lines.forEach(l => {
				if(l.trim().length == 0)
					return;
				var data = l.trim().split('\t');
				ppp.push({
					id: data[0],
					lat: parseFloat(  (""+data[1]).replace(",",".") ),
					lng: parseFloat ( (""+data[2]).replace(",",".") ),
					regDate: data[3],
					posDate:  this.format_date( this.change_tz( data[4], 'Europe/Rome') ),
					speed: data[5],
					street: data[6],
					sat: data[7],
					blv: data[8],
					bpr: data[9],
					fix: data[10],
					course: data[11],
				})
			}); 
			
			
            ppp.sort(function(a,b) {
                var dtA = new Date( b.posDate ).getTime();
                var dtB = new Date( a.posDate ).getTime();
                if(dtA < dtB)
                    return -1;
                else if(dtB > dtA)
                    return 1;
                else
                    return 0;
            });

			return ppp;
			
		},
        
		set_download_progress: function(total, current) {
			this.progress_total = total;
			this.progress_current = current;
		},

		
        format_date: function(dtStr) {
			var dt = new Date(dtStr);
            var d =  dt.getFullYear() + "-" 
                    + (1 + dt.getMonth()  < 10 ? "0" + (1 + dt.getMonth()) : (1 + dt.getMonth())) + "-" 
                    + ( dt.getDate() < 10 ? "0" + dt.getDate() : ""+dt.getDate()) ;
            
            var h = dt.getHours() >= 10 ? dt.getHours() : "0" + dt.getHours();
            var m = dt.getMinutes() >= 10 ? dt.getMinutes() : "0" + dt.getMinutes();
            var s = dt.getSeconds() >= 10 ? dt.getSeconds() : "0" + dt.getSeconds();

            return d + " " + h + ":" + m + ":" + s;

        },
        
        get_offset_date: function(str) {
            var dt = new Date( str );           
            if( dt.getTimezoneOffset() < this.isDST(dt))
                dt.setTime(dt.getTime() + (2*60*60*1000));		
            else
                dt.setTime(dt.getTime() + (1*60*60*1000));		

                
            return dt;
        }, 

        to_utc: function(str) {
            var dt = new Date( str ); 		
            return dt;
        },

        add_time: function(str, h) {
            var dt = new Date( str );
                dt.setTime(dt.getTime() + (h*60*60*1000));				
            return dt;
        }, 

        change_tz: function(date, tzString) {
            return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));  
        },

        isDST: function(d) {
            let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
            let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
            return Math.max(jan, jul) !== d.getTimezoneOffset();    
        },

        download_data_full: async function() {
			this.set_download_progress(0,0);
            // Facciamo la richiesta
            try
            {
				var vm = this;
                this.loading = 1;
                var request = await this.$http.request({
					url: "history/" + this.device.id + "/" + 
                            this.format_date( this.to_utc( this.min_date ) ) + "/" 
                            +  this.format_date( this.to_utc( this.max_date ) ) + "/full",
					method: 'GET',
					onDownloadProgress: function(progressEvent) {
						console.log("Progress ... ", progressEvent);
							
						var client = progressEvent.target;
						if (client.readyState >= 2) {
							var l = client.getResponseHeader("X-Content-Length");
							if(l)
								vm.set_download_progress(l,progressEvent.loaded);
						}

					}
				});  

				if(request.status == 200)
                {
					// Prendiamo tutte le righe
					var lines = request.data.trim().split('\n');

					// Decodifichiamo le righie
					this.history = {
						deviceId: this.device.id,
						totalPoints: lines.length,
						minId: 0,
						maxId: 0,
						ids: []
					};


                    // Aggiorniamo la history
                    this.loading = 3;

                    // Generiamo i percorsi
                    const ppp = this.parsePoints(lines); 
                    this.points = new Map();
                    var paths = this.compute_path(ppp);

                    // Salviamo i punti
                    const vm = this;
                    ppp.forEach(pp => {
						vm.history.ids.push(pp.id);
                        vm.points.set(pp.id, pp);
                    });
					vm.history.ids.reverse();
                    this.history.totalPoints = vm.history.ids.length;

                    // Aggiorna il punto
                    this.range_id = this.history.totalPoints;
                    this.$emit('on-points', ppp);
                    this.$emit('on-paths', paths);
                } 
            }
            catch(err)
            {
                // Siamo in errore
                this.loading = -1;
            }
        }
    }
}
</script>

<style>
.btn.btn-light.active {
	color: red;
	font-weight: bolder;;
}
</style>