<template>
    <Led :active="active" :powered="powered" text="RTE" />
</template>
<script>
import socketio from 'socket.io-client';   
import Led from './Led.vue';

export default {
    name: 'RTE',
    props: {
        device_id: { required: true }
    },

    data: function () {
        return {
            dpixel_socket: null,
            dpixel_client_state: {
                is_auth: false,
            },
            active: false,
            powered: false,
        };
    },
    mounted: function () {
        this.start();
    },

    destroyed: function() {
        if(this.dpixel_socket != null)
            this.dpixel_socket.disconnect();
    },

    methods: {
        /**
         * Avvia il client Dpixel
         */
        dpixel_start_client: function (uid, token, events) {
            var vm = this;
            // Creiamo lo stato
            vm.dpixel_client_state = {
                is_auth: false,
            };
            // Crea il socket
            this.dpixel_socket = socketio('https://deltapixelgps.com:385');
            // Eventi importanti: disconnessione
            this.dpixel_socket.on("disconnect", reason => {
                // Ci siamo scollegati   
                console.log("client disconnesso.");
                if (reason == 'io server disconnect') {
                    // Disconnesso dal server: facciamo qualcosa?
                }
                vm.dpixel_client_state.is_auth = false;
                if (events && events.on_connection_state)
                    events.on_connection_state(false);
            });
            // Eventi importanti: connessione
            this.dpixel_socket.on("connect", () => {
                // Ci siamo appena collegati      
                console.log("client collegato.");
                // Richiama l'evento
                if (events && events.on_connection_state)
                    events.on_connection_state(true);
                // Avviamo l'autenticazione
                vm.dpixel_client_auth(uid, token);
            });
            // Registriamo prima l'ascolto degli eventi
            this.dpixel_socket.on('auth', function (msg) {
                // Salviamo lo stato dell'auth
                vm.dpixel_client_state.is_auth = msg.is_auth;
                // Siamo connessi?
                console.log("Risultato auth: " + vm.dpixel_client_state.is_auth + "\tReason:" + msg.reason);
                // Autenticazione
                if (events.on_auth)
                    events.on_auth(msg);
            });
            // Risultato
            this.dpixel_socket.on('point', function (data) {

                // Convertiamo il campo punto
                var point = {};
                for (let [key, value] of Object.entries(data.point)) {
                    var ks = (key[0]+"").toLowerCase() + (key+"").substring(1);
                    point[ks] = value;
                }

                if (events && events.on_remote_point)
                    events.on_remote_point(data.device_id, point);
            });
            this.dpixel_socket.on('device_prop', function (data) {
                if (events && events.on_device_prop)
                    events.on_device_prop(data);
            });
        },
        // Inviamo il comando di autenticazione
        dpixel_client_auth: function (uid, token) {
            // Invia il comando di auth
            console.log("invio auth per dispositivo: " + this.device_id);
            this.dpixel_socket.emit('auth', {
                uid: uid,
                token: token,
                device_id: this.device_id
            });
        },
        start: function () {
            var vm = this;
            this.dpixel_start_client("1", "secret", {
                // Evento lanciato quando cambia lo stato della connessione
                on_connection_state: function (is_connected) {
                    // Se is_connected = true -> ci siamo collegati
                    // Se is_connected = false -> ci siamo sccollegati
                    console.log("Stato connessione cambiato: " + (is_connected ? "Si" : "No"));
                    vm.active = is_connected;
                },
                // Evento lanciato quando c'è l'autenticazione
                on_auth: function (data) {
                    // Se data.is_auth = true -> siamo autenticati. Il campo data.reason contiene "OK".
                    // Se data.is_auth = false -> non siamo autenticati. Il campo data.reason contiene con un testo descrittivo dell'errore.
                    console.log("Sei autenticato? " + (data.is_auth ? "Si" : "No"));
                    vm.powered = data.is_auth;
                },
                // Evento lanciato quando si riceve un punto
                on_remote_point: function (device_id, point) {
                    console.log("Nuovo punto", device_id, JSON.parse(JSON.stringify(point)));
                    if(window.on_remote_points !== undefined)
                        window.on_remote_points(device_id, [point]);
                }
            });
        }
    },
    components: { Led }
}
</script>