import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'

// Global
window.dpixel_session = {

    data: new Map(),

    getItem: function(key) {
        var value = localStorage.getItem(key);
        //window.dpixel_session.data.get(key);
        if (value === undefined)
            return null;
        else
            return value;
    },

    setItem: function(key, value) {
        // window.dpixel_session.data.set(key, value);
        localStorage.setItem(key, value);
    },


    removeItem: function(key) {
        // window.dpixel_session.data.set(key, value);
        localStorage.removeItem(key);
    },

    clear: function() {
        // window.dpixel_session.data = new Map();
        localStorage.clear();
    }
};

// Boostrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false

// Aggiungiamo axios 
Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = 'https://deltapixelgps.com/api';

// Vue Google Maps
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAAWHYku8gGdIDk_d7eqEEznoQ8aTWorek',
        libraries: 'places,drawing,geometry', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)
    },
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')