<template>
    <directions-renderer :options='{preserveViewport:true, suppressMarkers: true}' 
			v-if="directions != null" :directions="directions" 
			/>
</template>
<script>
import DirectionsRenderer from './DirectionsRenderer.js'
import { headingDistanceTo } from 'geolocation-utils'

export default {
    components: { DirectionsRenderer },

    props: {
        from_position: { required: true, default: null },
        to_position: { required: true, default: null },
        enabled: { required: true, default: false }
    },

    data: function () {
        return {
            last_distance: 0,
            directions: null,
            getting_route: false,
            directionsService: null,
            interval: null,
			instructions: null,
        }
    },

    watch: {
        enabled: function(val) {
            if(!val)
            {
                // Azzeriamo il tutto per resettare
                this.last_request_code = '';
                this.directions = null;
            }
        }
    },

    mounted: function() {
        this.directionsService = new window.google.maps.DirectionsService();
        window.drouter = this;
        this.interval = setInterval(this.map_calc_route, 1000);
    },

    beforeDestroy: function() {
        clearInterval(this.interval);
    },

    methods: {
		
		on_directions: function() { 
			this.instructions = [];
			var steps = this.directions.routes[0].legs[0].steps;
			for(var i = 0; i < steps.length; i++)
				this.instructions.push(steps[i]);
			this.$emit('on-instructions', this.instructions);
		},
		
        map_calc_route: function () {
            if (!this.enabled)
                return;

            if (this.from_position == null || this.to_position == null)
                return;

            // Se sta già facendo una richiesta, lascia stare
            if (this.getting_route)
                return;
 
            // Calcola la distanza
            var df = headingDistanceTo(
                    { lat: this.from_position.lat, lon: this.from_position.lng }, 
                    { lat: this.to_position.lat, lon: this.to_position.lng } 
                );


            // Se abbiamo una distanza iniziale precediamo al check
            if(this.last_distance != 0)
            {
                // Se sono stati fatti almeno 50 metri
                if( Math.abs( this.last_distance - df.distance) <= 50 )
                {
                    // .. non procediamo!
                    console.log("Routing saltato, troppo vicino!");
                    return;
                }
            }

            // Salviamo la distanza
            this.last_distance = df.distance;
 
            // Diciamo al routing che stiamo andando a questa posizione
            this.getting_route = true;

            // Generiamo la richiesta
            var request = {
                origin: this.from_position,
                destination: this.to_position,
                travelMode: window.google.maps.TravelMode.DRIVING
            };

            // Effettuiamo il routing
            var vm = this; 
            this.directionsService.route(request, function (result, status) {
                if (status == window.google.maps.DirectionsStatus.OK) 
                {
                    if(vm.enabled) 
                    {
                        console.log(result.geocoded_waypoints);
                        vm.directions = result;
                        vm.$emit('on-directions', result);
						vm.on_directions();
                    }
                }
                vm.getting_route = false;
            });
        }
    }
    
}
</script>    