<template>
    <GmapMarker v-if="has_position"
        :position="position"
        :clickable="false"
        :draggable="false"
        :icon="icon"
    />
</template>

<script>
import { headingDistanceTo } from 'geolocation-utils'

export default {
    props: { 
    },

    data: function() {
        return {  
            has_position: false,
            nav: null,
            position: { lat: 0, lng: 0 }, 
            degree: 0,
            latest_heading_position: null,
        }
    },
  
    mounted: function() { 
        this.update_position();
    },

    computed: { 
        icon: function() { 
            return {
                url: require('../assets/mycar.png'),
                size: {height: 32, width: 32},
                origin: { x: 0, y: 0 },
                anchor: { x: 16, y: 16 },
                scaledSize: {height: 32, width: 32},
            };
        }
    },
 
    beforeDestroy: function() {
        if(this.nav != null)
        {
            navigator.geolocation.clearWatch(this.nav);
            this.nav = null;
        }
    },
     
    methods: { 

        update_position: function() {
            var vm = this;

            // Ottiene la posizione attuale
            this.nav = navigator.geolocation.watchPosition(
                position => {
                    
                    // Calcola la distanza
                    var df = headingDistanceTo(
                            { lat: vm.position.lat, lon: vm.position.lng }, 
                            { lat: position.coords.latitude, lon: position.coords.longitude } 
                        );

					/*
                    if(!isNaN(position.coords.heading) && position.coords.heading !== null)
                    {
                        vm.degree = position.coords.heading;
                        console.log("HEADING FROM GEO", vm.degree);
                        vm.$emit('on-degree', vm.degree);
                    }
					*/
					if(!window.google)
						return; 
					
					if(!window.google.maps)
						return; 
						
                    // Sotti i 10 metri di distanza scartiamo i punti
                    if(vm.has_position && df.distance < 10)
                    {
                        console.log("SCARTATO");
                        return false;
                    }


					var point1 = new window.google.maps.LatLng(vm.position.lat, vm.position.lng);
					var point2 = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
					var heading = window.google.maps.geometry.spherical.computeHeading(point1,point2);


                    vm.position.lat = position.coords.latitude;
                    vm.position.lng = position.coords.longitude;
					/*
                    if(isNaN(position.coords.heading) ||  position.coords.heading == null)
                    {
                        vm.degree = df.heading;
                        console.log("HEADING FROM CALC", vm.degree);
                    }
					*/

					// Nuova versione
					vm.degree = heading;
					console.log("HEADING FROM CALC", heading);

					vm.has_position = true; 
                    vm.$emit('my-position', vm.position); 
                    vm.$emit('on-degree', vm.degree);
                },
                error => {
                    console.log(error.message);
                    // E' in errore
                    vm.has_position = false;
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            )
        }   
    }
}
</script>

<style>

</style>